import React from "react";
import ImageBanner from "../../components/atoms/banner/imageBanner";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import SolutionsHeader from "../../molecules/solutions/SolutionsHeader";
import SolutionsServices from "../../molecules/solutions/SolutionsServices";

import { useStaticQuery, graphql } from "gatsby";
import SolutionsWrapperRevamped from "../../molecules/solutions/SolutionsWrapperRevamped";
import backgroundImg from "../../images/servicesassets/banners/productivityEn.png";

import MobileIn from "../../images/servicesassets/banners/ProductivityMobileIn.png";
import MobileEn from "../../images/servicesassets/banners/ProductivityMobileEn.png";
import MobileJa from "../../images/servicesassets/banners/ProductivityMobileJa.png";
import { useLanguage } from "../../languageContext";

const Productivity = () => {
  const currentLanguage = useLanguage();

  const data = useStaticQuery(graphql`
    query GetProductivityData {
      gcms {
        solutionsPages(where: { solutionsPageType: PRODUCTIVITY }) {
          heading
          paragraph
          solutionsPageSlides {
            paragraph
            slideHeading
            listItems
            url
            slideImage {
              url
            }
          }
        }
      }
    }
  `);
  return (
    <Layout isNavWhite={true} isNavTransparent={true} hideMap hideFAQ>
      <SEO
        canonicalLink={"https://www.goken-global.com/solutions/productivity/"}
        title="Productivity Solutions: Boost Productivity with Goken"
        facebookDescription={`Goken provides automation solutions, workforce training, and language services in USA., India and Japan. It focuses on productivity and efficiency improvements to remain competitive in the market.

`}
        facebookTitle={`Goken | 360° Approach in Productivity - Automation Solutions | Workforce Training | Language Services

`}
        description="Enhance efficiency and reduce costs with Goken Global's productivity solutions tailored to your needs."
        keywords={"automation solutions,workforce training "}
      ></SEO>
      {data.gcms && (
        <>
          <SolutionsHeader
            headingOne={data.gcms.solutionsPages[0].heading}
            paragraph={data.gcms.solutionsPages[0].paragraph}
            btnText="Talk To An Expert"
            page="productivity"
            mobileImage={
              currentLanguage === "en"
                ? MobileEn
                : currentLanguage === "in"
                ? MobileIn
                : currentLanguage === "ja"
                ? MobileJa
                : MobileEn
            }
            mobileAltText={"Productivity Mobile Engineering Graphic"}
          />
          {/* <SolutionsWrapperRevamped
            img={backgroundImg}
            heading={data.gcms.solutionsPages[0].heading}
            para={data.gcms.solutionsPages[0].paragraph}
            btnText="Talk To An Expert"
            page="productivity"
          /> */}
          <SolutionsServices
            headingOne="Our Productivity Services"
            headingTwo="to enhance the product"
            headingThree="building experience"
            SliderItems={data.gcms.solutionsPages[0].solutionsPageSlides}
          ></SolutionsServices>
        </>
      )}
      <ImageBanner
        heading="Interested to learn more about Productivity?"
        link="/contactus/"
        btnText="Get in touch"
      ></ImageBanner>
    </Layout>
  );
};

export default Productivity;
